<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="trainingForm"
            >
              <form
                  class="emails-form"
                  @submit.prevent="handleSubmit(updateTraining)"
              >
                <label>Content</label>
                <div class="row">
                  <div class="col">
                    <vue2-tinymce-editor id="training" v-model="user.settings.misc.training"></vue2-tinymce-editor>
                  </div>

                </div>
                <b-form-group class="mt-3">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
export default {
  components:{
    Vue2TinymceEditor
  },
  data() {
    return {
      loadingUpdate:false,
      user:{
        settings:{
          misc:{

          }
        }
      }
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
  },

  methods: {
    updateTraining(){
      this.loadingUpdate = true

      let params = {
        training: this.user.settings.misc.training,
        setting_form:'training',
        admin_setting_id:this.user.settings.id
      }
      this.$store
          .dispatch('app/editAdminSettings', params)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.trainingForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
  },
}
</script>
